

.ms-nagative-10 {
  margin-left: -10px;
}

.rounded-rem-1 {
  border-radius: 1rem;
}

.rounded-top-rem-1 {
  border-top-right-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
}

.house-incharge-card:hover {
  box-shadow: 0 0 15px rgb(0 0 0 / 20%) !important;
}

.house-incharge-card-voter:hover {
  z-index: 1;
}

.overflow-x-auto {
  overflow-x: auto;
}