
.header-background-color {
  position: fixed;
  z-index: 2;
  width: 100%;
  top: 0%;
  background-color: rgb(52, 52, 125);
}

.header-menu-style {
  padding-left: 10%;
}