.voters {
  margin-top: 4%;
  margin-left: 15%;
}

.normal-font {
  font-size: 10px !important;
}

.table-header-font {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.table-header-style {
  background-color: rgb(243, 242, 242);
}

.header-style {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.voter-filter-style {
  padding-top: 5% !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.pagination > .page-item {
  padding: 0em 0em !important;
  margin-top: 1em;
}

.pagination {
  display: inline-flex !important;
}

.input-label-style {
    z-index: 100;
    position: absolute;
    top: 0px;
    left: 2em;
    background-color: white;
    padding: 0px 5px;
}

.input-style {
  position: relative;
}

.table>:not(caption)>*>* {
  border-bottom-width: 0px !important;
}

.sorticon {
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
  padding-right: .81rem!important;
  padding-left: .81rem!important;
  background-color: #6c757d !important;
  color: #1f2d3d !important;
}

.pagination > .active {
  font-weight: 700 !important;
  font-size: unset !important;
}