:root {
  --join-bjd-header-color: #0f5902;
}


.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 .active{
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #070707 !important;
}

.sidenav .nav-active .active {
  background-color: rgb(224 224 224) !important;
}

.main-menu a.active {
  background-color: rgb(224 224 224) !important;
}

.no-underline{
  text-decoration: none;
}
.width-60{
  width: 60%;
}

/*model*/



      .model-card{
        position: absolute !important;
        /* top: 7%; */
        right: 0%;
        margin-top: 15px !important;
       }
       .popup-content {
        position: initial !important;
       }

       .model-upper{

        height: 100px;

       }

       .upper img{
        width: 100%;
        border-top-left-radius: 5px !important;
        border-top-right-radius: 5px !important;
       }

       .upper {
        position: relative;
       }

       .user{
        position: relative;
       }

       .profile img{

        
        height: 80px;
        width: 80px;
        margin-top:2px;

       
       }

       .profile{

        position: absolute;
        top:-50px;
        left: 38%;
        height: 90px;
        width: 90px;
        border:3px solid #fff;

        border-radius: 50%;

       }

       .follow{

        border-radius: 15px;
        padding-left: 20px;
        padding-right: 20px;
        height: 35px;
       }

       .stats span{

        font-size: 29px;
       }

.gradient-custom {
/* fallback for old browsers */
background: #f6d365;

/* Chrome 10-25, Safari 5.1-6 */
background: -webkit-linear-gradient(to right bottom, #6f42c1, #3498db);

/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
background: linear-gradient(to right bottom, #6f42c1, #3498db)
}



.container > p{
    font-size: 32px;
}
.question{
    width: 75%;
}
.options{
    position: relative;
    padding-left: 40px;
}
#options label{
    display: block;
    margin-bottom: 15px;
    font-size: 14px;
    cursor: pointer;
}
.options input{
    opacity: 0;
}
.checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #555;
    border: 1px solid #ddd;
    border-radius: 50%;
}
.options input:checked ~ .checkmark:after {
    display: block;
}
.options .checkmark:after{
    content: "";
  width: 10px;
    height: 10px;
    display: block;
  background: white;
    position: absolute;
    top: 50%;
  left: 50%;
    border-radius: 50%;
    transform: translate(-50%,-50%) scale(0);
    transition: 300ms ease-in-out 0s;
}
.options input[type="radio"]:checked ~ .checkmark{
    background: #007bff;
    transition: 300ms ease-in-out 0s;
}
.options input[type="radio"]:checked ~ .checkmark:after{
    transform: translate(-50%,-50%) scale(1);
}
.btn-primary{
    background-color: #555;
    color: #ddd;
    border: 1px solid #ddd;
}
.btn-primary:hover{
    background-color: #21bf73;
    border: 1px solid #21bf73;
}
.btn-success{
    padding: 5px 25px;
    background-color: #21bf73;
}
@media(max-width:576px){
    .question{
        width: 100%;
        word-spacing: 2px;
    }
    .fixed-navbar{
      position: fixed;
      width: 100%;
    }
}
.voterSearch{
    width: 30%;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
    overflow-y: hidden !important; 
    overflow-x: hidden !important;
}

.pro-menu .active{
    background-color: red;
}



/*Sidebar nav*/



/* Fixed sidenav, full height */
.sidenav {
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 8px !important;
}


.single-nav-link{
    padding: 6px 8px 6px 16px;
    height: 50px; 
    width: 100%;
    text-decoration: none; 
    font-size: 20px;
    text-align: left; 
}


/* Style the sidenav links and the dropdown button */
.sidenav a, .dropdown-btn {
  padding: 6px 8px 6px 16px;
  height: 50px;
  text-decoration: none;
  font-size: 20px;
  color: #ffffff;
  display: block;
  border: none;
  background: none;
  width:100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

/* On mouse-over */
.sidenav a:hover, .dropdown-btn:hover {
  color: #f1f1f1;
}

/* Main content */
.main {
  margin-left: 200px; /* Same as the width of the sidenav */
  font-size: 20px; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

/* Add an active class to the active dropdown button */
.active-link {
  color: white;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  display: none;
  background-color: #262626;
  /* padding-left: 8px; */
}

.dropdown-container a {
  padding-left: 20px !important;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}
.nav-active{
    display: block !important;
}


/*end Sidebar*/

.fa-caret-down:before {
  content: '\f0da' !important;
}

.sidenav .active-link .fa-caret-down:before {
  content: '\f0dd' !important;
}

[class*=sidebar-dark-] {
  background-color:  #111 !important;
}

.brand-link {
  background-color:  rgb(54, 54, 54) !important;
}

.dropdown .dropdown-menu {
  padding: unset !important;
}

.brand-link .brand-image {
  margin-left: unset !important;
  max-height: 40px !important;
}

.brand-header-color {
  background-color: var(--join-bjd-header-color);
}

.icon-color-hover:hover svg path {
  fill: var(--join-bjd-header-color) !important;
}

.icon-color-hover:hover {
  color: var(--join-bjd-header-color) !important;
  border-color: var(--join-bjd-header-color) !important;
}

@media screen and (max-width: 480px) {
  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .text-wrap-auto {
    text-wrap: auto !important;
  }

  .jbjd-w-md-50 {
    width: 100% !important;
  }

  .brand-image-banner {
    width: 200px !important;
    height: 200px !important;
  }
}

@media screen and (max-width: 768px) {
  .jbjd-w-md-50 {
    width: 100% !important;
  }
}

@media screen and (min-width: 910px) {
  .overflow-md-unset {
    overflow: unset !important;
  }
}

.jbjd-w-md-50 {
  width: 50%;
}

.main-header .nav-link {
  height: 60px !important;
}

.brand-image-banner {
  width: 300px;
  height: 300px;
}

/* Active Membership thank you styles start */

@media screen and (max-width: 320px) {
  .membership-thank-you-card {
    max-width: 280spx !important;
    width: 280px !important;
  }

  .membership-thank-you-card .card-body .member-details .member-image {
    width: 75px !important;
  }

  .membership-thank-you-card .card-body .member-details .member-image .image {
    width: 75px !important;
    min-width: 75px !important;
    max-width: 75px !important;
    height: 75px !important;
  }

  .member-bottom-icon {
    width: 100px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .member-name, .member-assembly, .membership-number {
    font-size: 10px !important;
  }

  .membership-thank-you-card .card-body .member-details .member-image img {
    width: 70px !important;
    max-width: 70px !important;
    min-width: 70px !important;
  }

  .membership-thank-you-card .card-body .member-details {
    position: absolute;
    bottom: 8% !important;
    left: 3% !important;
    right: 3% !important;
  }

  .membership-thank-you-card .card-body .member-details .member-image {
    top: -30% !important;
  }
}

@media screen and (min-width: 321px) and (max-width: 480px) {
  
  .membership-thank-you-card {
    max-width: 300px !important;
    width: 300px !important;
  }

  .member-name, .member-assembly, .membership-number {
    font-size: 10px !important;
  }

  .membership-thank-you-card .card-body .member-details .member-image {
    width: 75px !important;
  }

  .membership-thank-you-card .card-body .member-details {
    left: 5% !important;
    right: 5% !important;
    bottom: 8% !important;
  }

  .membership-thank-you-card .card-body .member-details .member-image img {
    width: 75px !important;
    min-width: 75px !important;
  }

  .membership-thank-you-card .card-body .member-details .member-image .image {
    width: 75px !important;
    min-width: 75px !important;
    max-width: 75px !important;
    height: 75px !important;
  }

  .member-bottom-icon {
    width: 115px !important;
  }
}

.membership-thank-you-card {
  max-width: 600px;
  width: 600px;
  margin: 20px auto;
}

.membership-thank-you-card .card-body {
  background-image: url('../public/join-bjd-thank-you-bg.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  aspect-ratio: 9 / 16;
  margin: 0px;
  padding: 0px;
  position: relative;
  width: 100%;
}

.membership-thank-you-card .card-body .member-details {
  position: absolute;
  bottom: 15%;
  left: 10%;
  right: 10%;
}

.membership-thank-you-card .card-body .member-details .member-image {
  width: 100px;
  position: absolute;
  top: -25%;
  left: 50%;
  transform: translateX(-50%);
}

.membership-thank-you-card .card-body .member-details .member-image {
  max-width: 100px;
  width: 100px;
  height: 100px;
}

.membership-thank-you-card .card-body .member-details .member-image .image {
  border: 3px solid #ffe80b;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
  aspect-ratio: 1/1;
  max-width: 100px;
  width: 100px;
  height: 100px;
}

.membership-thank-you-card .card-body .member-details .member-image img {
  border-radius: 15px;
  width: 95px;
  max-width: 95px;
  min-width: 95px;
  object-fit: scale-down;
  /* aspect-ratio: 1/1; */
}

.member-details-card {
  border: 3px solid #ffe80b;
  border-radius: 15px;
  position: relative;
}

.memberdetails {
  margin: 25px 10px 10px 10px;
}

.member-name, .member-assembly {
  font-weight: 600;
  color: #fff;
  font-size: 20px;
}

.membership-number {
  position: absolute;
  top: 100%;
  transform: translateY(-50%) translateX(-50%);
  padding: 5px 10px;
  background-color: #ffe80b;
  color: #006038;
  border-radius: 50px;
  width: 80%;
  left: 50%;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

.member-bottom-icon {
  width: 125px;
  display: block;
  cursor: pointer;
}

.dropdown-menu-item:hover {
  background-color: #006038 !important;
  color: #fff !important;
}

/* Active Membership thank you styles end */

@media screen and (max-width: 320px) {
  .profile-image-preview {
      width: 75px !important;
      min-width: 75px !important;
      max-width: 75px !important;
      height: 75px !important;
  }

  .profile-image-preview img {
    width: 70px !important;
    max-width: 70px !important;
    min-width: 70px !important;
  }

  .jb-shadow {
    width: 95% !important;
  }
}

@media screen and (min-width: 321px) and (max-width: 480px) {
  .profile-image-preview {
      width: 75px !important;
      min-width: 75px !important;
      max-width: 75px !important;
      height: 75px !important;
  }

  .profile-image-preview img {
      width: 70px !important;
      min-width: 70px !important;
  }

  .jb-shadow {
    width: 75% !important;
  }
}

.profile-image-preview {
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 15px;
  border: 3px solid #ffe80b;
  background-color: #fff;
  max-width: 100px;
  width: 100px;
  height: 100px;
  margin: auto;
}

.profile-image-preview img {
  border-radius: 15px;
  width: 95px;
  max-width: 95px;
  min-width: 95px;
  object-fit: scale-down;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .container .tb-w-75 {
    width: 75% !important;
  }
}

.imagecrop-body {
  height: 250px;
}

.profile-choose-file-label {
  padding-top: 8px;
  padding-bottom: 8px;
  border-right-color: var(--bs-border-color) !important;
  padding-right: 10px;
  background-color: var(--bs-border-color);
  padding-left: 10px;
}

label .profile-choose-file-label {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

label:has( .profile-choose-file-label ) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.jb-shadow {
  box-shadow: 0px 0px 10px #006038 !important;
  width: 35%;
}

.jb-button-animation {
  position: relative;
  overflow: hidden;
}

.jb-button-animation::after {
  content: "";
  animation: ShineAnim 2s ease infinite;
  animation-delay: 0s;
  position: absolute;
  display: block;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
}

@keyframes ShineAnim {
  0% {
    left: -100%;
  }
  10% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.jb-button-style {
  border: 1px solid var(--join-bjd-header-color) !important;
  color: var(--join-bjd-header-color);
}

.jb-button-style:hover {
  background-color: var(--join-bjd-header-color);
  color: #fff !important;
}

@media screen and (max-width: 325px) {
  .header-mobile-margin-bottom {
    margin-bottom: 10px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.d-unset {
  display: unset !important;
}