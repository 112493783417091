
.resize-observer-style {
  left: 0px;
  right: auto;
}

.size-auto-observer-style {
  height: calc(100% + 1px); 
  float: left;
}

.content-glue-style {
  margin: 0px -8px;
  width: 72px;
  height: 551px;
}

.os-content-style {
  padding: 0px !important;
  height: 100%;
  width: 100%;
}

.os-viewport-native-scrollbars-invisible-polls {
  overflow: scroll;
}

.os-scrollbar-handle-polls {
  width: 38.0208%;
  transform: translate(0px, 0px);
}

.os-scrollbar-handle-polls-corner {
  height: 47.2246%;
  transform: translate(0px, 0px);
}