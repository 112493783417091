.fw-900 {
  font-weight: 900;
}

.fw-800 {
  font-weight: 800;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.fw-300 {
  font-weight: 300;
}

.fw-200 {
  font-weight: 200;
}

.fw-100 {
  font-weight: 100;
}

.w-5 {
  width: 5%;
}

.w-6 {
  width: 6%;
}

.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}

.ms-10 {
  margin-left: 10rem !important;
}

.login-container  {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.login-container .row {
  margin: 0 auto;
}

.pt-p-20 {
  padding-top: 20% !important;
}

.data-loader div[style] {
  display: block !important;
}

.card-min-height {
  min-height: 60vh;
}

.error {
  margin: unset !important;
  vertical-align: middle !important;
}

.error-icon {
  font-size: 8rem !important;
}

.overflow-x {
  overflow-x: auto;
}

.overflow-y {
  overflow-y: auto;
}

.table-top-border-none {
  border-top: unset !important;
}


.slider-selection {
	background: #f77500 !important;
}
.slider-success .slider-selection {
	background-color: #5cb85c !important;
}
.slider-primary .slider-selection {
	background-color: #428bca !important;
}
.slider-info .slider-selection {
	background-color: #5bc0de !important;
}
.slider-warning .slider-selection {
	background-color: #f0ad4e !important;
}
.slider-danger .slider-selection {
	background-color: #d9534f !important;
}
.slider.slider-horizontal {
    width: 100% !important;
    height: 20px;
}
.slider-handle {
	background-color: #fff !important;
	background-image: none !important;
	-webkit-box-shadow: 1px 1px 24px -2px rgba(0,0,0,0.75) !important;
	-moz-box-shadow: 1px 1px 24px -2px rgba(0,0,0,0.75) !important;
	box-shadow: 1px 1px 24px -2px rgba(0,0,0,0.75) !important;
}

.slider-strips .slider-selection {
	background-image: repeating-linear-gradient(-45deg, transparent, transparent 5px, rgba(255,252,252,0.08) 5px, rgba(252,252,252,0.08) 10px) !important;
	background-image: -ms-repeating-linear-gradient(-45deg, transparent, transparent 5px, rgba(255,252,252,0.08) 5px, rgba(252,252,252,0.08) 10px) !important;
	background-image: -o-repeating-linear-gradient(-45deg, transparent, transparent 5px, rgba(255,252,252,0.08) 5px, rgba(252,252,252,0.08) 10px) !important;
	background-image: -webkit-repeating-linear-gradient(-45deg, transparent, transparent 5px, rgba(255,252,252,0.08) 5px, rgba(252,252,252,0.08) 10px) !important; 
}
.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #bdbdbd !important;
    text-align: center;
    background-color: transparent !important;
    border-radius: 4px;
}
.tooltip.top .tooltip-arrow {
    display: none !important;
}
.slider .tooltip.top {
    margin-top: -25px !important;
}
.well {
	background: transparent !important;
	border: none !important;
	box-shadow: none !important;
	width: 100% !important;
	padding: 0;
}
.slider-ghost .slider-track {
	height: 5px !important;
}
.slider-ghost .slider-handle {
	top: -2px !important;
	border: 5px solid #f77500;
}
.slider-success.slider-ghost .slider-handle {
	border-color: #5cb85c;
}
.slider-primary.slider-ghost .slider-handle {
	border-color: #428bca;
}
.slider-info.slider-ghost .slider-handle {
	border-color: #5bc0de;
}
.slider-warning.slider-ghost .slider-handle {
	border-color: #f0ad4e;
}
.slider-danger.slider-ghost .slider-handle {
	border-color: #d9534f;
}


/* slider */

.rc-slider-rail {
  height: 10px !important;
}

.rc-slider-handle {
  width: 20px !important;
  height: 20px !important;
}

.rc-slider-track {
  height: 10px !important;
}

.sidebar-mini.sidebar-collapse .main-sidebar, .sidebar-mini.sidebar-collapse .main-sidebar::before {
  width: 4rem !important;
}

.pro-sidebar .pro-menu.submenu-bullets .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  display: none !important;
}

.modal-footer {
  align-items: flex-end !important;
}

.pro-sidebar { 
  min-width: 250px !important;
  width: 250px !important;
}
.house-voters .rc-tooltip-inner {
  padding: 8px 10px !important;
  color: #fffdfd !important;
  text-align: left !important;
  text-decoration: none !important;
  background-color: #000000 !important;
  border-radius: 0.5rem !important;
  min-height: 34px !important;
  border: 1px solid #000000 !important;
}

.house-voters, .house-voters .rc-tooltip-arrow {
  border-radius: 0.5rem !important;
  border-top-color: #000000 !important;
}

/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
  
} */

@media screen and (min-width: 576px) {
  
  /* .table-form-label-display {
    display: block !important;
  } */

}

@media screen and (max-width: 576px) {
  
  .table-form-label-display {
    display: none !important;
  }

}

.step-before::before {
  content: '-';
}

.active-step-stepper {
  color: white;
  background-color: #5cb85c;
  transform: scale(1.1);
  box-shadow: 0px 3px 15px #00000050;
     -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.1); 
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.1); 
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 200ms ease-in;
}


.custom-table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  font-style: inherit;
}

.custom-table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

.custom-table tr {
  border: 1px solid #ddd;
  padding: .35em;
}

.custom-table th,
.custom-table td {
  padding: .625em;
}

@media screen and (max-width: 600px) {

  .custom-table caption {
    font-size: 1.3em;
  }
  
  .custom-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
  }
  
  .custom-table tr {
    border-bottom: 3px solid #ddd;
    margin-bottom: 10px;
  }
  
  .custom-table td {
    border: none !important;
    display: block;
    font-size: .8em;
  }
  
  .custom-table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a .custom-table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
  
  .custom-table td:last-child {
    border-bottom: 0;
  }

  .custom-table-tr-desktop, .custom-table-th-desktop {
    display: none !important;
  }

  .custom-table-tr-mobile, .custom-table-th-mobile {
    display: block !important;
  }

  .event-calendar .fc .fc-view-harness[style] {
    height: 425.926px !important;
  }
}

@media screen and (min-width: 600px) {
  .custom-table-tr-mobile, .custom-table-th-mobile {
    display: none !important;
  }

}